import React from 'react';
import { useForm } from 'react-hook-form';
import Form from 'react-bootstrap/Form';
import emailjs from 'emailjs-com'; // Import EmailJS
import './contact_form.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSortDown } from '@fortawesome/free-solid-svg-icons';
import Successful_modal from '../../Common_Component/modals/succcessful-modal/Successful_modal';

const Contact_form = () => {
  const [modalShow, setModalShow] = React.useState(false);

  // React Hook Form
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  // EmailJS form submission function
  const onSubmit = (data) => {

    console.log("onSubmit", data)


  
      const templateParams = {
        to_name: "Guddu Kumar", 
        firstName: data?.firstName, 
        lastName: data?.lastName, 
        email: data?.email, 
        contactNumber: data?.contactNumber, 
        industry: data?.industry, 
        message: data?.message,  // Customize your message body
      };

      emailjs
        .send(
          "service_7y4x3nc", // Replace with your EmailJS service ID
          "template_uw1mq28", // Replace with your EmailJS template ID
          templateParams,
          "sJevM79FHEIIVsWlu" // Replace with your EmailJS public key
        )
        .then(
          (response) => {
            console.log("Email successfully sent!", response.status, response.text);
            alert("Email sent successfully!");
          },
          (error) => {
            console.error("Failed to send email:", error);
            alert("Failed to send email.");
          }
        );

      // emailjs
      //   .send(
      //     'YOUR_SERVICE_ID', // Replace with your EmailJS Service ID
      //     'YOUR_TEMPLATE_ID', // Replace with your EmailJS Template ID
      //     {
      //       firstName: data.firstName,
      //       lastName: data.lastName,
      //       email: data.email,
      //       contactNumber: data.contactNumber,
      //       industry: data.industry,
      //       message: data.message,
      //     },
      //     'YOUR_USER_ID' // Replace with your EmailJS User ID (public key)
      //   )
      //   .then(
      //     (response) => {
      //       console.log('SUCCESS!', response.status, response.text);
      //       setModalShow(true); // Show success modal
      //       reset(); // Reset form fields
      //     },
      //     (err) => {
      //       console.error('FAILED...', err);
      //     }
      //   );
    };

    return (
      <>
        <section className="Contact_form">
          <Form onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
              {/* First Name */}
              <div className="col-lg-6 mb-4">
                <div className="form-group">
                  <Form.Label>First Name*</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter your First Name"
                    {...register("firstName", { required: "First name is required" })}
                  />
                  {errors.firstName && <span className="text-danger">{errors.firstName.message}</span>}
                </div>
              </div>

              {/* Last Name */}
              <div className="col-lg-6 mb-4">
                <div className="form-group">
                  <Form.Label>Last Name*</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter your Last Name"
                    {...register("lastName", { required: "Last name is required" })}
                  />
                  {errors.lastName && <span className="text-danger">{errors.lastName.message}</span>}
                </div>
              </div>

              {/* Email */}
              <div className="col-lg-6 mb-4">
                <div className="form-group">
                  <Form.Label>Email*</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Enter your Email"
                    {...register("email", {
                      required: "Email is required",
                      pattern: { value: /^\S+@\S+$/i, message: "Invalid email address" },
                    })}
                  />
                  {errors.email && <span className="text-danger">{errors.email.message}</span>}
                </div>
              </div>

              {/* Contact Number */}
              <div className="col-lg-6 mb-4">
                <div className="form-group">
                  <Form.Label>Contact Number*</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter your Contact Number"
                    {...register("contactNumber", {
                      required: "Contact number is required",
                      pattern: { value: /^[0-9]{10}$/, message: "Enter a valid 10-digit number" },
                    })}
                  />
                  {errors.contactNumber && <span className="text-danger">{errors.contactNumber.message}</span>}
                </div>
              </div>

              {/* Industry */}
              <div className="col-lg-12 mb-4">
                <div className="form-group">
                  <Form.Label>Industry*</Form.Label>
                  <Form.Select
                    {...register("industry", { required: "Industry is required" })}
                    aria-label="Default select example"
                  >
                    <option value="">Select your Industry</option>
                    <option value="IT">IT</option>
                    <option value="Finance">Finance</option>
                    <option value="Healthcare">Healthcare</option>
                  </Form.Select>
                  <button className="srcbtn" type="button">
                    <FontAwesomeIcon className="srchicn" icon={faSortDown} />
                  </button>
                  {errors.industry && <span className="text-danger">{errors.industry.message}</span>}
                </div>
              </div>

              {/* Message */}
              <div className="col-lg-12 mb-4">
                <div className="form-group">
                  <Form.Label>How we can help you *</Form.Label>
                  <Form.Control
                    style={{ height: "100px" }}
                    as="textarea"
                    placeholder="Type here..."
                    {...register("message", { required: "Message is required" })}
                  />
                  {errors.message && <span className="text-danger">{errors.message.message}</span>}
                </div>
              </div>

              {/* Submit Button */}
              <div className="col-lg-12 mb-4">
                <div className="form-group">
                  <button className="submit-btn" type="submit">
                    Submit
                  </button>
                </div>

                {/* <div className='form-group'>
                <button className='submit-btn'type="button" onClick={() => setModalShow(true)}>Submit</button>
              </div> */}

              </div>
            </div>
          </Form>
        </section>

        {/* Success Modal */}
        <Successful_modal
          show={modalShow}
          onHide={() => setModalShow(false)}
          message="Message Sent Successfully"
        />
      </>
    );
  };

  export default Contact_form;
