import React from 'react';
import { useForm } from 'react-hook-form';
import emailjs from 'emailjs-com';
import Form from 'react-bootstrap/Form';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import '../../contact-us/contact-form/contact_form.css';
import Successful_modal from '../../Common_Component/modals/succcessful-modal/Successful_modal';

const Job_apply_form = () => {
    const { register, handleSubmit, formState: { errors }, reset } = useForm();
    const [modalShow, setModalShow] = React.useState(false);

    // Function to send form data
    const onSubmit = (data) => {




        const templateParams = {
            to_name: "Guddu Kumar",
            firstName: data?.firstName,
            lastName: data?.lastName,
            email: data?.email,
            contactNumber: data?.contactNumber,
            coverLetter: data?.coverLetter,
            file: data?.cv[0] // Customize your message body
        };


      

        emailjs
            .send(
                "service_7y4x3nc", // Replace with your EmailJS service ID
                "template_8r6bk9w", // Replace with your EmailJS template ID
                templateParams,
                "sJevM79FHEIIVsWlu" // Replace with your EmailJS public key
            )
            .then(
                (response) => {
                    console.log("Email successfully sent!", response.status, response.text);
                    alert("Email sent successfully!");
                },
                (error) => {
                    console.error("Failed to send email:", error);
                    alert("Failed to send email.");
                }
            );



        // const serviceID = "YOUR_EMAILJS_SERVICE_ID";
        // const templateID = "YOUR_EMAILJS_TEMPLATE_ID";
        // const userID = "YOUR_EMAILJS_USER_ID";

        // const templateParams = {
        //     firstName: data.firstName,
        //     lastName: data.lastName,
        //     email: data.email,
        //     contactNumber: data.contactNumber,
        //     coverLetter: data.coverLetter,
        //     file: data.cv[0].name, // File name
        // };

        // emailjs.send(serviceID, templateID, templateParams, userID)
        //     .then((response) => {
        //         console.log("SUCCESS!", response.status, response.text);
        //         setModalShow(true); // Show success modal
        //         reset(); // Clear form
        //     })
        //     .catch((err) => {
        //         console.error("FAILED...", err);
        //     });




    };

    return (
        <>
            <h1 className='applytxt'>Apply for this Position</h1>
            <section className='Contact_form'>
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <div className='row'>
                        {/* First Name */}
                        <div className='col-lg-6 mb-4'>
                            <Form.Group>
                                <Form.Label>First Name*</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter your First Name"
                                    {...register('firstName', { required: "First Name is required" })}
                                />
                                {errors.firstName && <span className="text-danger">{errors.firstName.message}</span>}
                            </Form.Group>
                        </div>

                        {/* Last Name */}
                        <div className='col-lg-6 mb-4'>
                            <Form.Group>
                                <Form.Label>Last Name*</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter your Last Name"
                                    {...register('lastName', { required: "Last Name is required" })}
                                />
                                {errors.lastName && <span className="text-danger">{errors.lastName.message}</span>}
                            </Form.Group>
                        </div>

                        {/* Email */}
                        <div className='col-lg-6 mb-4'>
                            <Form.Group>
                                <Form.Label>Email*</Form.Label>
                                <Form.Control
                                    type="email"
                                    placeholder="Enter your Email"
                                    {...register('email', {
                                        required: "Email is required",
                                        pattern: { value: /^\S+@\S+$/i, message: "Invalid email address" }
                                    })}
                                />
                                {errors.email && <span className="text-danger">{errors.email.message}</span>}
                            </Form.Group>
                        </div>

                        {/* Contact Number */}
                        <div className='col-lg-6 mb-4'>
                            <Form.Group>
                                <Form.Label>Contact Number*</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter your Contact Number"
                                    {...register('contactNumber', {
                                        required: "Contact Number is required",
                                        pattern: { value: /^[0-9]+$/, message: "Invalid number format" }
                                    })}
                                />
                                {errors.contactNumber && <span className="text-danger">{errors.contactNumber.message}</span>}
                            </Form.Group>
                        </div>

                        {/* Cover Letter */}
                        <div className='col-lg-12 mb-4'>
                            <Form.Group>
                                <Form.Label>Cover Letter *</Form.Label>
                                <Form.Control
                                    as="textarea"
                                    placeholder="Type your cover letter..."
                                    style={{ height: '100px' }}
                                    {...register('coverLetter', { required: "Cover Letter is required" })}
                                />
                                {errors.coverLetter && <span className="text-danger">{errors.coverLetter.message}</span>}
                            </Form.Group>
                        </div>

                        {/* Upload CV */}
                        <div className='col-lg-6 mb-4'>
                            <Form.Group>
                                <Form.Label>Upload your CV*</Form.Label>
                                <Form.Control
                                    type="file"
                                    accept=".pdf,.doc,.docx"  // Accept only PDF or DOC files
                                    {...register('cv', {
                                        required: "CV is required",
                                        validate: (value) => {
                                            // Check if the selected file type is valid
                                            const file = value[0]; // value contains an array of files
                                            if (file && !/(\.pdf|\.doc|\.docx)$/i.test(file.name)) {
                                                return "Only PDF, DOC, or DOCX files are allowed";  // Custom error message
                                            }
                                            return true;
                                        }
                                    })}
                                />
                                {errors.cv && <span className="text-danger">{errors.cv.message}</span>}
                            </Form.Group>

                        </div>

                        <div className='col-lg-12 mb-4'>
                            {/* <Form.Check
                                type="checkbox"
                                id="consent"
                                label="By using this form you agree with the storage and handling of your data by this website"
                                {...register('consent', { required: "You must agree before submitting" })}
                            />
                            {errors.consent && <span className="text-danger">{errors.consent.message}</span>} */}
                            <div className='form-group'>
                                <Form>
                                    {['checkbox'].map((type) => (
                                        <div key={`default-${type}`} className="mb-3">
                                            <Form.Check
                                                type={type}
                                                id={`default-${type}`}
                                                label="By using this form you agree with the storage and handling of your data by this website"
                                            />
                                        </div>
                                    ))}
                                </Form>
                            </div>

                        </div>

                        {/* Submit Button */}
                        <div className='col-lg-12 mb-4'>
                            <div className='form-group'>
                                <button className='submit-btn' type="submit">Submit</button>
                            </div>
                            {/* <div className='form-group'>
                                <button className='submit-btn' type='button' onClick={() => setModalShow(true)}>Submit</button>
                            </div> */}
                        </div>
                    </div>
                </Form>
            </section>

            <Successful_modal
                show={modalShow}
                onHide={() => setModalShow(false)}
                message="Applied for job position Successfully!!"
            />
        </>
    );
};

export default Job_apply_form;
